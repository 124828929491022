import FETCH from '@/shared/utils/security/fetch';
import { appConfig } from '@/shared/common/config';
import { stringify } from 'query-string';
import { TENANT_NAME_ENUM } from '@/core/constants';
import { mergeWith } from 'lodash';
import { getEpgs } from './epg';
import {
  EPISODE_TYPE,
  CURATED_LIVE,
  CHANNEL_TYPE,
  TRAILER_TYPE,
  SHOW_TYPE,
} from '@/core/constants/contentConstants';
import { getFirstSeason } from '@/core/method/contentMethod';

/**
 *
 * @param {{content_id: String}} params
 * @returns {Promise<import('@/core/models/Model').AdTag>}
 */
/**
 * @typedef {import('@/core/models/Content').TContent} TContent
 */

export const CONTENT_DETAIL_SELECT_FIELDS = [
  'can_preview',
  'category',
  'category_slug',
  'content_categories',
  'genre',
  'has_free_content',
  'id',
  'group',
  'images',
  'is_new_release',
  'is_premium',
  'long_description',
  'restriction',
  'num_first_episode_preview',
  'people',
  'provider_slug',
  'regions',
  'release_date',
  'released_episode_count',
  'runtime',
  'seasons',
  'short_description',
  'slug',
  'title',
  'total_episodes',
  'episode',
  'trailers',
  'type',
  'video_source',
  'subtitles',
  'price',
  'min_sub_tier',
  'release_year',
  'metadata',
  'disclaimer',
  'start_on',
  'schedule_publish',
  'license_period',
  'unpublished_time',
  'duration',
  'badges',
  'show_info',
  'release_date',
  'episode_name',
  'payment_type',
];

export const CONTENT_DETAIL_SEASON_SELECT_FIELDS = ['id', 'slug', 'title', 'min_sub_tier'];

export const CONTENT_DETAIL_TRAILER_SELECT_FIELDS = [
  'id',
  'images',
  'is_new_release',
  'title',
  'min_sub_tier',
  'slug',
];

export const CONTENT_VIEWS_SELECT_FIELDS = [
  'current_season',
  'id',
  'slug',
  'is_watchable',
  'progress',
  'youtube_video_id',
  'link_play',
  'play_info',
  'payment_infors',
  'is_favorite',
  'badges',
  'cwl_info',
  'warning_message',
  'price',
  'payment_type',
];

export const CONTENT_SEASON_SELECT_FIELDS = [
  'episodes',
  'id',
  'slug',
  'title',
  'is_new_release',
  'min_sub_tier',
];

export const CONTENT_SEASON_ITEMS_SELECT_FIELDS = [
  'id',
  'slug',
  'title',
  'video_source',
  'is_premium',
  'is_new_release',
  'images',
  'can_preview',
  'episode',
  'type',
  'is_watchable',
  'min_sub_tier',
  'show_info',
];

/**
 *
 * @param {String} contentId
 * @returns {Promise<TContent>}
 */
export function getDetail(contentId) {
  const { authorization, imageResolutionScale, tenantSlug } = appConfig;
  const select = JSON.stringify({
    Content: CONTENT_DETAIL_SELECT_FIELDS,
    SeasonList: CONTENT_DETAIL_SEASON_SELECT_FIELDS,
    TrailerSerializerDocumentation: CONTENT_DETAIL_TRAILER_SELECT_FIELDS,
  });
  const data = { select, image_resolution_scale: imageResolutionScale };
  const headers = { authorization };

  const queryParams = stringify(data);
  const path = `/tenants/${tenantSlug}/contents/${contentId}/detail?${queryParams}`;
  return FETCH(path, 'GET', headers);
}

/**
 *
 * @param {String} contentId
 * @returns {Promise<TContent>}
 */
export function viewDetail(contentId, query = null) {
  const { authorization, tenantSlug } = appConfig;

  const select = JSON.stringify({
    Content: [
      ...CONTENT_VIEWS_SELECT_FIELDS,
      tenantSlug === TENANT_NAME_ENUM.SCTV ? 'drm_session_info' : '',
    ],
  });

  const { cb } = query ?? {};
  let data = { select };

  if (cb) {
    data.cb = cb;
  }

  const queryParams = stringify(data);

  const headers = { authorization };
  const path = `/tenants/${tenantSlug}/contents/${contentId}/view?${queryParams}`;
  return FETCH(path, 'GET', headers);
}

/**
 * @param {String} contentID
 * @returns {Promise<TContent>}
 */
export const getContentMerged = (contentID, query = null) =>
  Promise.all([getDetail(contentID), viewDetail(contentID, query)]).then(
    ([staticContent, personalizeContent]) => {
      const pageMerged = mergeWith(staticContent, personalizeContent);
      return pageMerged;
    },
  );

/**
 * @param {String} contentID
 * @returns {Promise<TContent>}
 */
export const getSeason = contentId => {
  const { authorization, imageResolutionScale, tenantSlug } = appConfig;
  const headers = { authorization };

  const select = JSON.stringify({
    SeasonDetail: CONTENT_SEASON_SELECT_FIELDS,
    Content: CONTENT_SEASON_ITEMS_SELECT_FIELDS,
  });
  const data = { select, image_resolution_scale: imageResolutionScale };
  const queryParams = stringify(data);

  const path = `/tenants/${tenantSlug}/seasons/${contentId}/?${queryParams}`;
  return FETCH(path, 'GET', headers);
};

/**
 * @param {String} childContentId
 * @returns {Promise<TContent>}
 */
export const getProgramShow = childContentId => {
  const { authorization, imageResolutionScale, tenantSlug } = appConfig;
  const headers = { authorization };
  const select = JSON.stringify({
    Content: CONTENT_DETAIL_SELECT_FIELDS,
  });
  const data = { select, image_resolution_scale: imageResolutionScale };
  const queryParams = stringify(data);
  const path = `/tenants/${tenantSlug}/contents/${childContentId}/program?${queryParams}`;
  return FETCH(path, 'GET', headers);
};

/**
 *
 * @param {String} channelId
 * @returns {Promise<import('@/core/models/Model').EPGFeed[]>}
 */

export const getEpgOfChannel = channelId => getEpgs({ channel_id: channelId });

export function getAdsVideo(params = {}) {
  const { authorization, tenantSlug, platformSlug } = appConfig;

  const queryParams = stringify(params);
  const headers = { authorization };

  const path = `/tenants/${tenantSlug}/platforms/${platformSlug}/video_ads/?${queryParams}`;
  return FETCH(path, 'GET', headers);
}

/**
 *
 * @param {String} contentId
 * @param {{hasShow?: boolean, hasSeason?: boolean, hasAds?:boolean, hasEpg?: boolean, trailerSlug?: String, query?: *}} [options]
 * @returns {Promise<TContent>}
 */
export const getPrefetchRelatedContent = async (contentId, options = {}) => {
  if (!contentId) {
    return Promise.reject('ContentID is required');
  }
  try {
    const { hasShow, hasSeason, hasAds, hasEpg, trailerSlug = '', query } = options;
    const collectContentsByIdAndSlug = {};
    const prefetchRelatedContentCallable = [];
    const mainContentApi = () =>
      getContentMerged(contentId, query).then(res => {
        collectContentsByIdAndSlug.mainContent = res;
        collectContentsByIdAndSlug[res.slug] = res;
        collectContentsByIdAndSlug[res.id] = res;
        if (res?.type === SHOW_TYPE) {
          collectContentsByIdAndSlug.show = res;
          collectContentsByIdAndSlug[res.slug] = res;
          collectContentsByIdAndSlug[res.id] = res;
        }
        return res;
      });

    const trailerApi = () =>
      getContentMerged(trailerSlug).then(res => {
        collectContentsByIdAndSlug.trailer = res;
        collectContentsByIdAndSlug[res.slug] = res;
        collectContentsByIdAndSlug[res.id] = res;
        return res;
      });

    const videoAdsApi = () =>
      getAdsVideo({ content_id: contentId }).then(res => {
        collectContentsByIdAndSlug.videoAds = res;
        return res;
      });
    const initialContentApis = [mainContentApi];

    if (hasAds) {
      initialContentApis.push(videoAdsApi);
    }
    if (trailerSlug) {
      initialContentApis.push(trailerApi);
    }

    const [{ value: mainContent, status, reason }] = await Promise.allSettled(
      initialContentApis.map(apiCall => apiCall()),
    );
    if (status === 'rejected') {
      throw reason;
    }
    const { slug: mainContentSlug, group, current_season, type: mainContentType } = mainContent;
    const firstSeason = getFirstSeason.call(mainContent);

    const seasonID = group ?? firstSeason?.id;

    if (seasonID && hasSeason) {
      const seasonApi = () =>
        getSeason(seasonID).then(res => {
          collectContentsByIdAndSlug.season = res;
          collectContentsByIdAndSlug.parent = res;
          collectContentsByIdAndSlug[res.slug] = res;
          collectContentsByIdAndSlug[res.id] = res;
          return res;
        });
      prefetchRelatedContentCallable.push(seasonApi);
    }

    if (hasShow && [EPISODE_TYPE].includes(mainContentType)) {
      const showApi = () =>
        getProgramShow(contentId).then(res => {
          collectContentsByIdAndSlug.show = res;
          collectContentsByIdAndSlug[res.slug] = res;
          collectContentsByIdAndSlug[res.id] = res;
          return res;
        });
      prefetchRelatedContentCallable.push(showApi);
    }
    if (hasEpg && [CHANNEL_TYPE, CURATED_LIVE].includes(mainContentType)) {
      const epgApi = () =>
        getEpgOfChannel(contentId).then(res => {
          collectContentsByIdAndSlug.epgs = res;
          return res;
        });
      prefetchRelatedContentCallable.push(epgApi);
    }
    await Promise.allSettled(prefetchRelatedContentCallable.map(api => api()));
    return {
      ...mainContent,
      prefetchRelatedContent: collectContentsByIdAndSlug,
    };
  } catch (error) {
    throw error;
  }
};
