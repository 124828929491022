import FETCH from '../utils/security/fetch';
import queryString from 'query-string';
import moment from 'moment';

const EPG_FEED_SELECT_FIELDS = [
  'end_at',
  'id',
  'is_blackout',
  'slug',
  'start_at',
  'title',
  'type',
  'link_play',
  'subtitles',
  'play_date',
  'badges',
];
const getEpgs = (params = {}) => {
  let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const scheduleDate = moment().format('YYYY-MM-DD');
  const select = JSON.stringify({
    EpgFeedResponse: ['items'],
    EPGFeed: EPG_FEED_SELECT_FIELDS,
  });
  const data = { timezone, select, schedule_date: scheduleDate, ...params };
  const queryParams = queryString.stringify(data);
  const path = `/backend/cm/epg/?${queryParams}`;
  return FETCH(path, 'GET', {});
};

const getIPInfo = () => {
  return new Promise((resolve, reject) => {
    FETCH('http://worldtimeapi.org/api/ip', 'GET', {})
      .then(response => {
        resolve(response);
      })
      .catch(error => {
        reject(error);
      });
  });
};

export { getEpgs, getIPInfo };
